import * as constants from 'constants/intl-constants';

export function setFormats(formats) {
    return {
        type: constants.SET_FORMATS,
        formats: formats,
    };
}

export function setMessages(moduleNames, messages) {
    return {
        type: constants.SET_MESSAGES,
        messages,
        moduleNames,
    };
}

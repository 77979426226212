import globalMessages from 'intl/global-messages';

import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

export function getDisplayName(Comp) {
    return Comp.displayName || Comp.name || 'Component';
}

export function isPristine(initial, data) {
    // Special handling for Lexical EditorState
    if (initial?._nodeMap && data?._nodeMap) {
        // Compare the serialized states instead of the objects
        try {
            return JSON.stringify(initial.toJSON()) === JSON.stringify(data.toJSON());
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('Failed to compare Lexical editor states:', e);
            return false;
        }
    }

    if (isNull(initial)) {
        return false;
    }
    if (initial === data) {
        return true;
    }
    if (typeof initial === 'object') {
        if (isNull(data)) {
            return false;
        }
        if (typeof data !== 'object') {
            return false;
        }
        const dataKeys = Object.keys(data);
        for (let i = 0; i < dataKeys.length; i++) {
            const key = dataKeys[i];
            if (!isPristine(initial[key], data[key])) {
                return false;
            }
        }
    } else if ((initial || data) /* allow '' to equate to undefined or null */ && initial !== data) {
        return false;
    }
    return true;
}

export function inputValidError(v, isRequired = false) {
    const messages = {};
    let type = 'errors';
    if (!isRequired) {
        type = 'warnings';
    }
    if (isEmpty(v)) {
        messages[type] = globalMessages.cannotBeEmpty;
    }
    return messages;
}

export function selectItemValidError(v, isRequired = false) {
    const messages = {};
    let type = 'errors';
    if (!isRequired) {
        type = 'warnings';
    }
    if (isUndefined(v) || isNull(v)) {
        messages[type] = globalMessages.cannotBeEmpty;
    }

    return messages;
}

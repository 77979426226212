// externals
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {routerActions} from 'actions/router-actions';
import {setUserSessionAuthenticationData} from 'actions/session-actions';

// components
import AcknowledgementModal from 'components/modals/acknowledgement-modal';
import ProgressPulsingCircles from 'components/progress/progress-pulsing-circles';

// constants
import ErrorCodes from 'constants/error-codes';
import messages from 'intl/anonymous-messages';
import globalMessages from 'intl/global-messages';

// styles
import S from './passthrough.less';

// utils
import * as ApiUtils from 'utils/api-utils';
import * as ComponentUtils from 'utils/component-utils';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setUserSessionAuthenticationData,
            ...routerActions,
        },
        dispatch,
    );
}

// @ts-expect-error TS(1270) FIXME: Decorator function return type 'FC<WithIntlProps<a... Remove this comment to see the full error message
@injectIntl
// @ts-expect-error TS(1270) FIXME: Decorator function return type 'ConnectedComponent... Remove this comment to see the full error message
@connect((state) => ({}), mapDispatchToProps)
export default class Passthrough extends React.Component {
    static propTypes = {
        intl: PropTypes.any,
        location: PropTypes.any,
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
        setUserSessionAuthenticationData: PropTypes.func,
        settings: PropTypes.any,
    };

    constructor(props) {
        super(props);

        this.setStateSafe = ComponentUtils.setStateSafe.bind(this);
        this.state = {
            showErrorModal: false,
        };
    }

    componentDidMount() {
        const {location} = this.props;
        this.mounted = true;
        const searchParams = new URLSearchParams(location.search);
        // This seems verbose, but it makes troubleshooting a lot easier than reusing the same query param
        const passthroughToken = searchParams.get('passthroughToken');
        const clusterToken = searchParams.get('clusterToken');
        const clientToken = searchParams.get('clientToken');

        if (passthroughToken && passthroughToken.length > 0) {
            ApiUtils.executeApiRequest(
                ApiUtils.createRequest(
                    // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
                    {},
                    {
                        endpoint: '/auth/passthrough',
                        method: 'put',
                        data: {
                            passthroughToken,
                        },
                    },
                ),
            )
                .then(this.onAuthSuccess)
                .catch(this.showErrorModal);
        } else if (clusterToken && clusterToken.length > 0) {
            ApiUtils.executeApiRequest(
                ApiUtils.createRequest(
                    // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
                    {},
                    {
                        endpoint: '/auth/cluster/cluster-passthrough',
                        method: 'put',
                        data: {
                            clusterToken,
                        },
                    },
                ),
            )
                .then(this.onAuthSuccess)
                .catch(this.showErrorModal);
        } else if (clientToken && clientToken.length > 0) {
            ApiUtils.executeApiRequest(
                ApiUtils.createRequest(
                    // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
                    {},
                    {
                        endpoint: '/auth/cluster/client-passthrough',
                        method: 'put',
                        data: {
                            clientToken,
                        },
                    },
                ),
            )
                .then(this.onAuthSuccess)
                .catch(this.showErrorModal);
        } else {
            throw new Error('Invalid passthrough request. No token found.');
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    onAuthSuccess = (response) => {
        // eslint-disable-next-line no-shadow -- SCLD-17998
        const {setUserSessionAuthenticationData} = this.props;

        setUserSessionAuthenticationData(response.data);
        this.toHome();
    };

    /**
     * Will send back to regular login if passthrough fails,
     * or dashboard on success.
     */
    toHome = () => {
        this.props.replace('/');
    };

    showErrorModal = (err) => {
        const errorCode = get(err, 'response.data.errorCode');
        let errorMessage;
        switch (errorCode) {
            case ErrorCodes.TOKEN_EXPIRED.errorCode:
                errorMessage = this.props.intl.formatMessage(messages.loginErrorPassthroughExpired);
                break;
            default:
                // Unknown error
                errorMessage = this.props.intl.formatMessage(messages.loginErrorPassthrough);
                break;
        }

        this.setStateSafe({
            showErrorModal: true,
            errorMessage,
        });
    };

    hideErrorModal = () => {
        this.setStateSafe({
            showErrorModal: false,
        });
        this.toHome();
    };

    render() {
        const {intl} = this.props;
        const {showErrorModal, errorMessage} = this.state;
        const {formatMessage} = intl;

        return (
            <div className={S.main}>
                <div className={S.boxWithLogo}>
                    <div className={S.simcaptureLogo} />
                    <div className={S.authenticatingText}>
                        <FormattedMessage {...messages.authenticating} />
                    </div>
                    <ProgressPulsingCircles />
                </div>
                <AcknowledgementModal
                    onClose={this.hideErrorModal}
                    modalTitle={formatMessage(globalMessages.error)}
                    open={showErrorModal}>
                    <Typography>{errorMessage}</Typography>
                </AcknowledgementModal>
            </div>
        );
    }
}

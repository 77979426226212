import SocketIOEvents, {
    type MESSAGING_EVENT_MONITOR_SEND_MESSAGE,
    type MESSAGING_EVENT_SP_SEND_MESSAGE,
} from 'shared-socket-io/socket-io-events';
import * as SocketIO from '../socket-io';

import type {Socket} from '../socket-io';

/**
 * Sends a websocket messageEvent to simcapture to broadcast a messageEvent to all users in a room
 */
const sendChatMessage = (
    socket: Socket,
    event: MESSAGING_EVENT_SP_SEND_MESSAGE | MESSAGING_EVENT_MONITOR_SEND_MESSAGE,
    roomId: string,
    messageEvent: object,
) => {
    return SocketIO.emit(
        event,
        {
            roomId,
            messageEvent,
        },
        socket,
    );
};

export const receiveMessages = (socket: Socket, callback: (roomId, messages) => void) => {
    SocketIO.on(SocketIOEvents.MESSAGING_EVENT_RECEIVE_MESSAGES, callback, socket);
};

export const markMessagesRead = (socket: Socket, messageIds: Array<string>) => {
    return SocketIO.emit(SocketIOEvents.MESSAGING_EVENT_READ_MESSAGES, {messageIds}, socket);
};

export const connectMonitorChat = (socket: Socket) => {
    return SocketIO.emit(SocketIOEvents.MESSAGING_EVENT_MONITOR_CONNECT, undefined, socket);
};

export const connectSpChat = (socket: Socket, roomId: string, lastChatUpdate: string = undefined) => {
    return SocketIO.emit(SocketIOEvents.MESSAGING_EVENT_SP_ENTER_ROOM, {roomId, lastChatUpdate}, socket);
};

export const monitorOpenRoomChat = (socket: Socket, roomId: string, lastChatUpdate: string = undefined) => {
    return SocketIO.emit(SocketIOEvents.MESSAGING_EVENT_MONITOR_ENTER_ROOM, {roomId, lastChatUpdate}, socket);
};

export const monitorCloseRoomChat = (socket: Socket, roomId: string) => {
    return SocketIO.emit(SocketIOEvents.MESSAGING_EVENT_MONITOR_LEAVE_ROOM, {roomId}, socket);
};

export const sendSpChat = (socket: Socket, roomId: string, messageEvent: object) => {
    return sendChatMessage(socket, SocketIOEvents.MESSAGING_EVENT_SP_SEND_MESSAGE, roomId, messageEvent);
};

export const sendMonitorChat = (socket: Socket, roomId: string, messageEvent: object) => {
    return sendChatMessage(socket, SocketIOEvents.MESSAGING_EVENT_MONITOR_SEND_MESSAGE, roomId, messageEvent);
};

/**
 * As a remote OSCE exam participant (learner or SP), enters the waiting room for any exam they are configured to be in.
 */
export const enterRemoteExamWaitingRoom = async (socket: Socket) => {
    return socket.emitWithAck(SocketIOEvents.EXAMS_ENTER_REMOTE_EXAM_WAITING_ROOM);
};

import axios from 'axios';
import * as Immutable from 'immutable';
import {onFailure} from './api';
import {createRequestAction, createSuccessAction, enforcePlainJSData} from './utils/calls-utils';

import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isString from 'lodash/isString';

export const CALL_EXTERNAL = Symbol('CALL EXTERNAL');

const OPTIONAL_REQUEST_CONFIG_ATTRIBUTES = Immutable.fromJS([
    'params',
    'progress',
    'responseType',
    'transformResponse',
    'onUploadProgress',
]);

const externalCallsMiddleware = (store) => (next) => (action) => {
    if (!action) {
        return;
    } else if (isNil(action[CALL_EXTERNAL])) {
        return next(action);
    } else if (__UNIT_TEST__) {
        // UNIT_TEST flag is set in webpack config
        return next({
            type: 'EXTERNAL_CALLS_DISABLED',
        });
    } else if (!isNil(action[CALL_EXTERNAL])) {
        return executeExternalAction(store, next, action, action[CALL_EXTERNAL]);
    }
};

const executeExternalAction = (store, next, callAction, callConfig) => {
    validateApiActionConfig(callConfig);
    next(createRequestAction(callAction, callConfig));
    const requestConfig = createRequestConfig(callConfig);
    if (isNull(requestConfig)) {
        return;
    }
    return axios(requestConfig)
        .then(onSuccess(next, callAction, callConfig))
        .catch(onFailure(next, callAction, callConfig));
};

const validateApiActionConfig = (callConfig) => {
    const {endpoint, types, method} = callConfig;

    if (isNil(method)) {
        throw new Error("External actions must have a method, e.g., 'get'");
    }

    if (!isString(endpoint)) {
        throw new Error(`External actions must have an 'endpoint' attribute, which must be a string.`);
    }

    if (
        !types.every((type) => {
            return typeof type === 'string';
        })
    ) {
        throw new Error('Action types must be strings.');
    }

    if (!types[0].includes('REQUEST')) {
        // eslint-disable-next-line
        console.warn('API action type might not be correct. First is for request:', types[0]);
    }

    if (!types[1].includes('SUCCESS')) {
        // eslint-disable-next-line
        console.warn('API action type might not be correct. Second is for success:', types[1]);
    }

    if (types.length > 2 && !types[2].includes('FAILURE')) {
        // eslint-disable-next-line
        console.warn('API action type might not be correct. Third is for failure:', types[2]);
    }
};

const createRequestConfig = (callConfig) => {
    /** @type {import('axios').AxiosRequestConfig} */
    const requestConfig = {
        url: callConfig.endpoint,
        method: callConfig.method.toLowerCase(),
        headers: {},
        data: enforcePlainJSData(callConfig.data),
    };

    if (callConfig.contentType) {
        requestConfig.headers['Content-Type'] = callConfig.contentType;
    }

    OPTIONAL_REQUEST_CONFIG_ATTRIBUTES.forEach((attribute) => {
        if (!isNil(callConfig[attribute])) {
            requestConfig[attribute] = callConfig[attribute];
        }
    });

    return requestConfig;
};

const onSuccess = (next, callAction, callConfig) => {
    return (response) => {
        next(createSuccessAction(callAction, callConfig, response));
        if (isFunction(callConfig.success)) {
            callConfig.success(Immutable.fromJS(response.data));
        }
    };
};

export default externalCallsMiddleware;

import * as Immutable from 'immutable';
// externals
import maxBy from 'lodash/maxBy';
import PropTypes from 'prop-types';
import React, {createContext, useState, useEffect} from 'react';

// internals
import * as SocketIO from '../../socket-io';

// api
import * as ExamsSocketApi from '../../api/exams-socket-api';

// shared
import {getClientMessagingNamespace} from 'shared-socket-io/socket-io-namespaces';

// types
import type {Socket} from 'socket.io-client';
import type {ImmutableMap} from 'types/immutable-types';

type setChatData = React.Dispatch<React.SetStateAction<ChatData>>;

export const ChatContext = createContext({
    chatData: null as ChatData,
    messagingSocket: null as Socket,
    rooms: Immutable.List(),
    setChatData: null as setChatData,
});

/**
 * Combines new messages with local history, ignoring any duplicates of messages we already have.
 *
 * @param {Immutable.List} currentMessageHistory
 * @param {Immutable.List} newMessages
 * @returns {Immutable.List}
 */
const mergeMessages = (currentMessageHistory, newMessages) => {
    if (currentMessageHistory.isEmpty()) {
        return newMessages;
    }

    let allMessages = currentMessageHistory;
    const messageIdsSet = Immutable.Set(allMessages.map((message) => message.get('messageId')));

    newMessages.forEach((message) => {
        if (!messageIdsSet.has(message.get('messageId'))) {
            allMessages = allMessages.push(message);
        }
    });

    return allMessages;
};

type MessageEvent = Immutable.Map<
    string,
    ImmutableMap<{
        messageId: string;
        clientId: string;
        userId: string;
        roomId: string;
        createdTs: string;
        message: string;
    }>
>;

type ChatData = ImmutableMap<{
    loggedInUser: ImmutableMap<{userId: string; firstName: string; lastName: string}>;
    messagesByRoomId: Immutable.Map<string, Immutable.List<MessageEvent>>;
    unreadMessagesByRoomId: Immutable.Map<string, Immutable.List<MessageEvent>>;
    expandedRoomId: string;
    activeRoomIds: Immutable.Set<string>;
    textFieldIsFocused: boolean;
    VISIBILITY_STATE: string;
}>;

const messageHandler = (userId: string, roomId: string, messages, setChatData: setChatData) => {
    setChatData((prev) => {
        const currentMessageHistory = prev.getIn(
            ['messagesByRoomId', roomId],
            Immutable.List(),
        ) as Immutable.List<MessageEvent>;

        messages = Immutable.fromJS(messages);
        const allMessages = mergeMessages(currentMessageHistory, messages);

        const unreadMessages = (
            prev.getIn(['unreadMessagesByRoomId', roomId], Immutable.List()) as Immutable.List<MessageEvent>
        ).toJS();
        messages.forEach((messageEvent) => {
            if (!messageEvent.getIn(['message', 'usersRead'], Immutable.Map()).has(userId)) {
                unreadMessages.push(messageEvent);
            }
        });

        const messagesCreatedTs = messages.toJS().map((message) => message.createdTs);
        const lastReceivedMessageTs = maxBy(messagesCreatedTs, (createdTs: string) => new Date(createdTs).getTime());
        setLastMessageUpdate(userId, lastReceivedMessageTs);

        return prev
            .setIn(['activeRoomIds'], prev.get('activeRoomIds').add(roomId))
            .setIn(['messagesByRoomId', roomId], allMessages)
            .setIn(['unreadMessagesByRoomId', roomId], Immutable.fromJS(unreadMessages));
    });
};

const setPersistentStorage = (userId, chatData) => {
    localStorage.setItem(`chatData-${userId}`, chatData);
};

export const setLastMessageUpdate = (userId, lastMessageUpdateTs) => {
    localStorage.setItem(`lastChatDataUpdate-${userId}`, lastMessageUpdateTs);
};

export const clearMessagingStorage = (userId) => {
    if (!userId) {
        return;
    }
    localStorage.removeItem(`chatData-${userId}`);
    localStorage.removeItem(`lastChatDataUpdate-${userId}`);
};

export const clearMessaging = () => {
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith('chatData') || key.startsWith('lastChatDataUpdate') || key === 'multiRoomChatVisibility') {
            localStorage.removeItem(key);
        }
    }
};

export const getLastChatDataUpdate = (userId) => localStorage.getItem(`lastChatDataUpdate-${userId}`);

const loadPersistentStorage = (userId) => {
    const persistedState = localStorage.getItem(`chatData-${userId}`);
    const lastUpdate = getLastChatDataUpdate(userId);
    if (persistedState && lastUpdate) {
        const persistedStateObject = JSON.parse(persistedState);
        persistedStateObject.activeRoomIds = Immutable.OrderedSet(persistedStateObject.activeRoomIds);
        return [Immutable.fromJS(persistedStateObject), new Date(lastUpdate)];
    }
    return [undefined, undefined];
};

const MessagingContext = (props) => {
    const {
        children,
        clientId,
        examInterfaceToken,
        isMultiRoom = false,
        loggedInUser,
        room,
        token,
        doMinimize = false,
    } = props;

    if (!token && !examInterfaceToken) {
        throw new Error('Either a token or examInterfaceToken is required');
    }

    const [chatData, setChatData] = useState<ChatData>(null);
    const [messagingReady, setMessagingReady] = useState(false);
    const [messagingSocket, setMessagingSocket] = useState<Socket>(null);
    const [rooms, setRooms] = useState(isMultiRoom ? Immutable.List() : Immutable.List([room]));

    const chatContext = {
        chatData,
        messagingSocket,
        rooms,
        setChatData: setChatData,
    };

    useEffect(() => {
        if (messagingReady) {
            setPersistentStorage(loggedInUser.get('userId'), JSON.stringify(chatData.toJS()));
        }
    }, [chatData, messagingReady, loggedInUser]);

    useEffect(() => {
        let messageSocket: SocketIO.Socket;
        const setup = async () => {
            const [localState, lastUpdate] = loadPersistentStorage(loggedInUser.get('userId'));
            let initialChatData =
                localState && lastUpdate
                    ? localState
                    : Immutable.fromJS({
                          activeRoomIds: Immutable.OrderedSet(),
                          expandedRoomId: null,
                          loggedInUser,
                          messagesByRoomId: Immutable.Map(),
                          roomsByRoomId: Immutable.Map(),
                          textFieldIsFocused: null,
                          unreadMessagesByRoomId: Immutable.Map(),
                      });
            if (doMinimize) {
                initialChatData = initialChatData.set('expandedRoomId', null);
            }
            setChatData(initialChatData.set('loggedInUser', loggedInUser));

            // https://socket.io/docs/v4/client-options/#query
            // The query parameters cannot be updated for the duration of the session
            // so changing the query on the client-side will only be effective when
            // the current session gets closed and a new one is created
            // so we need to force a new connection
            messageSocket = SocketIO.connectSocketIO(
                getClientMessagingNamespace(clientId),
                examInterfaceToken ? {examInterfaceToken} : {token},
                {query: {userId: loggedInUser.get('userId')}, forceNew: true},
            );

            const receiveMessagesCallback = (roomId, messages) =>
                messageHandler(loggedInUser.get('userId'), roomId, messages, setChatData);
            ExamsSocketApi.receiveMessages(messageSocket, receiveMessagesCallback);
            setMessagingSocket(messageSocket);

            if (isMultiRoom) {
                try {
                    const connectedRooms = Immutable.fromJS(
                        (await ExamsSocketApi.connectMonitorChat(messageSocket)).data,
                    );
                    const roomsByRoomId = Immutable.Map<string, ImmutableMap<{roomId: string}>>(
                        connectedRooms.map((r) => [r.get('roomId'), r]),
                    );
                    setChatData((prev) => {
                        return prev
                            .set('roomsByRoomId', roomsByRoomId)
                            .set(
                                'activeRoomIds',
                                Immutable.OrderedSet(
                                    prev.get('activeRoomIds').filter((roomId) => roomsByRoomId.has(roomId)),
                                ),
                            )
                            .set(
                                'expandedRoomId',
                                roomsByRoomId.has(prev.get('expandedRoomId'))
                                    ? prev.get('expandedRoomId')
                                    : roomsByRoomId.first()?.get('roomId'),
                            );
                    });
                    setRooms(connectedRooms);

                    if (lastUpdate) {
                        await Promise.all(
                            connectedRooms.map((r) => {
                                // TODO SCLD-15933 bulk enter rooms
                                return ExamsSocketApi.monitorOpenRoomChat(
                                    messageSocket,
                                    r.get('roomId'),
                                    lastUpdate?.toISOString(),
                                );
                            }),
                        );
                    }
                } catch (err) {
                    console.error(err);
                }
            } else {
                setRooms(Immutable.List([room]));
                await ExamsSocketApi.connectSpChat(messageSocket, room.get('roomId'), lastUpdate?.toISOString());
            }

            setMessagingReady(true);
        };
        setup();
        return () => {
            try {
                messageSocket.removeAllListeners();
                messageSocket.disconnect();
            } catch (err) {
                console.error(err);
            }
        };
    }, [loggedInUser]);

    useEffect(() => {
        if (doMinimize) {
            setChatData((prev) => prev.set('expandedRoomId', null));
        }
    }, [doMinimize, loggedInUser]);

    return (
        <div style={{zIndex: 999}}>
            {messagingReady && <ChatContext.Provider value={chatContext}>{children}</ChatContext.Provider>}
        </div>
    );
};

MessagingContext.propTypes = {
    children: PropTypes.node.isRequired,
    loggedInUser: PropTypes.object.isRequired,
    clientId: PropTypes.string.isRequired,
    examInterfaceToken: PropTypes.string,
    doMinimize: PropTypes.bool,
    isMultiRoom: PropTypes.bool,
    // @ts-expect-error TS(2345) FIXME: Argument of type 'typeof Map' is not assignable to... Remove this comment to see the full error message
    room: PropTypes.instanceOf(Immutable.Map),
    token: PropTypes.string,
};

export default MessagingContext;

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import CSSUtils from 'utils/css-utils';

import S from './form-label.less';

const {applyClassName} = CSSUtils;
const {string, bool, object, oneOfType} = PropTypes;
/**
 * @deprecated Use Formik and material-ui components instead.
 */
const FormLabel = ({label, id, required, labelClass, disabled, style}) => {
    const asteriskDisabledClass = applyClassName('requiredAsteriskDisabled', S, style);
    const asteriskClass = applyClassName('requiredAsterisk', S, style);

    const labelText = disabled ? S.textDisabled : S.text;
    const requiredClass = disabled ? asteriskDisabledClass : asteriskClass;

    return (
        <div className={labelClass}>
            {!isEmpty(label) ? (
                <label htmlFor={id} id={id} className={labelText}>
                    {label}
                </label>
            ) : null}
            {required && !isEmpty(label) ? <span className={requiredClass}>&nbsp;*</span> : null}
        </div>
    );
};

FormLabel.propTypes = {
    disabled: bool,
    label: string,
    id: string,
    required: bool,
    labelClass: oneOfType([object, string]),
    style: object,
};

FormLabel.defaultProps = {
    disabled: false,
    label: '',
    id: '',
    required: false,
    labelClass: S.label,
    style: {},
};

export default FormLabel;

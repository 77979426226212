import {GRAPH_COLORS} from 'constants/graph-constants';
// https://canvasjs.com/docs/charts/integration/react/
import CanvasJSReact from '../../../resources/canvasjs.react';

export const CanvasJS = CanvasJSReact.CanvasJS;

export const configureCanvasJS = function () {
    // add our app colors to the color set
    CanvasJS.addColorSet('simcapture', GRAPH_COLORS);
};

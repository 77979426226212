// externals
import {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

// actions
import {routerActions} from 'actions/router-actions';
import {setUserSessionAuthenticationData} from 'actions/session-actions';

// APIs
import * as ClientsApi from 'api/clients-api';

// components
import LoginProgress from 'components/login-progress';

// constants
import messages from 'intl/anonymous-messages';

/**
 * The user arrives here after being redirected by our SSO callback endpoint on
 * the backend. The redirect should provide the appropriate auth information on
 * the url query, which this view then uses to set up the user's session on the
 * UI. If the auth information is not on the url, the call to get user profile
 * will fail and cause LOGOUT_USER_REQUEST to be issued. The user will then be
 * redirected to the login view.
 *
 * If the SSO login attempt failed, the user will be redirected back to the
 * login view where an appropriate error message can be displayed and they can
 * try again.
 */
const LoginSSOSuccess = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const location = useLocation();
    const {formatMessage} = intl;

    const onLoginSuccess = async () => {
        const searchParams = new URLSearchParams(location.search);
        const authToken = searchParams.get('authToken');
        const userId = searchParams.get('userId');
        const clientId = searchParams.get('clientId');

        const client = await ClientsApi.fetchClient(clientId, userId, authToken);

        // puts the auth info into the session store
        dispatch(
            setUserSessionAuthenticationData({
                token: authToken,
                userId,
                clientId,
                institutionName: client.get('institutionName'),
            }),
        );

        dispatch(routerActions.replace('/'));
    };

    // executes once upon initial load
    useEffect(() => {
        onLoginSuccess();
    }, []);

    return <LoginProgress messageText={formatMessage(messages.authenticating)} />;
};

LoginSSOSuccess.propTypes = {};

LoginSSOSuccess.defaultProps = {};

export default LoginSSOSuccess;

import {createContext} from 'react';

import type {ButtonProps} from '@material-ui/core/Button';

export const ButtonContext = createContext<{
    disabled: boolean | null;
    size: ButtonProps['size'] | null;
}>({
    disabled: null,
    size: null,
});

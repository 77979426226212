export const ACCESSIBILITY_SETTINGS_PATH = '/accessibility';

export const ACCESSIBILITY_LOCAL_STORAGE_KEY = 'meteor:accessibility';

export type ThemeOptions = 'LIGHT' | 'DARK';

export const THEME_OPTIONS = {
    LIGHT: 'LIGHT' as ThemeOptions,
    DARK: 'DARK' as ThemeOptions,
};

export type AccessibilityOptions = {
    theme: ThemeOptions;
    reduceMotion: boolean;
};

export const ACCESSIBILITY_DEFAULT_VALUES: AccessibilityOptions = {
    theme: THEME_OPTIONS.LIGHT,
    reduceMotion: false,
};

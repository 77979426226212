// externals
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import forEach from 'lodash/forEach';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import merge from 'lodash/merge';

// actions
import {setFormats, setMessages} from 'actions/intl-actions';

// stores
import {store} from 'redux-store';

import type {LocaleModule} from 'constants/intl-constants';
// types
import type {DateTimeFormatOptions} from 'intl';

// When you import JSON files in Webpack 4+, the actual contents are under the
// returned object's `.default` attribute.
const importLocaleMessagesFile = (locale, moduleName) => {
    const fileName = `${moduleName}${locale === 'en-US' ? '' : `.${locale}`}.json`;
    return import(`../locale/${locale}/${fileName}`).then((module) => module.default);
};

const loadLocaleMessages = async (locale, moduleNames: LocaleModule[]): Promise<void> => {
    const importedMessages = await Promise.all(
        moduleNames.map((moduleName) => importLocaleMessagesFile(locale, moduleName)),
    );
    const mergedImportedMessages = merge({}, ...importedMessages);
    store.dispatch(setMessages(moduleNames, mergedImportedMessages));
};

/**
 * Given one more more UI module names, loads the corresponding JSON file containing translations for locale messages
 * pertaining to that module. Messages are merged into the intl store. Any modules already loaded are skipped. The
 * current locale is obtained from the session store to determine which locale's messages should be loaded.
 */
const ensureLocaleMessages = async (...moduleNames: LocaleModule[]): Promise<void> => {
    const {modules: loadedModuleNames} = store.getState().intl;
    const locale = store.getState().session.settings.get('locale');
    const unloadedModuleNames = difference(moduleNames, loadedModuleNames) as LocaleModule[];

    return await loadLocaleMessages(locale, unloadedModuleNames);
};

/**
 * Reloads any locale messages that have already been loaded. Typically this would need to be done if the user's locale
 * setting has changed after initially loading some messages for a different locale.
 */
const reloadLocaleMessages = async (locale): Promise<void> => {
    const {modules: loadedModuleNames} = store.getState().intl;
    return await loadLocaleMessages(locale, loadedModuleNames);
};

/**
 * Most browsers as of 2016 don't support setting the `timeZone` to anything
 * other than 'UTC'. Any other value throws an exception. It is also
 * possible that the server may send an invalid timezone setting for
 * whatever reason. As a fallback in these cases, we must use the last known
 * good timezone setting (i.e. not update the `timeZone` property).
 *
 * Note that by default we do not specify the value of the property, which
 * leads to the local browser time being used, which should work in all
 * browsers. Note also that this is NOT equivalent to specifying the local
 * timezone explicitly as the `timeZone` property, which will not work in
 * most browsers.
 */
const updateTimeFormats = function (hourTwelve, timezone) {
    const formats = store.getState().intl.formats;
    const updated = cloneDeep(formats);

    let tzInvalid = false;
    const testDate = new Date('2016-01-02T03:04:05Z');
    const testOptions: Partial<DateTimeFormatOptions> = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short',
        timeZone: timezone,
    };

    try {
        const dtf = new Intl.DateTimeFormat('en-US', testOptions);
        dtf.format(testDate);
    } catch (error) {
        console.error(error);
        tzInvalid = true;
    }

    if (!isUndefined(updated.date) && !isNull(updated.date)) {
        forEach(updated.date, (f) => {
            if (!tzInvalid) {
                f.timeZone = timezone;
            }
            f.hour12 = hourTwelve;
        });
    }
    store.dispatch(setFormats(updated));
};

export default {
    ensureLocaleMessages,
    reloadLocaleMessages,
    updateTimeFormats,
};

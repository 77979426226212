// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck This entire file is legacy and full of type check errors.
import PropTypes from 'prop-types';

import * as Immutable from 'immutable';

import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isUndefined from 'lodash/isUndefined';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import uniqueId from 'lodash/uniqueId';

import formManagerContextShape from 'components/forms/form-manager-context-shape';
import createFormGroupShape from './utils/create-form-group-shape';
import {getDisplayName, isPristine} from './utils/helpers';

const {any, bool, func, string} = PropTypes;

/**
 * Wraps all form fields providing all base functionality for the form components (ie: inputs selectors etc)
 * The `formControl` communicates directly to a its immediate `formManager` defined on the parent component.
 *
 * @deprecated Use Formik and material-ui components instead.
 * @param {Function} controlValidator Validation function which will be used to validate the component.
 * @returns {Function}
 */
export default function formControl(controlValidator = noop) {
    return function wrapWithControl(WrappedComponent) {
        class Control extends React.Component {
            static displayName = `FormControl(${getDisplayName(WrappedComponent)})`;
            static WrappedComponent = WrappedComponent;

            static contextTypes = {
                formManager: formManagerContextShape,
                formGroup: createFormGroupShape(PropTypes),
            };

            static propTypes = {
                name: string,
                value: any,
                defaultValue: any,
                required: bool,
                validator: func,
                onChange: func,
                formManager: formManagerContextShape, // we don't actually seem to use this feature but it has to match what gets passed on the context
            };

            static defaultProps = {
                validator: controlValidator,
                onChange: noop,
            };

            constructor(props, context) {
                super(props, context);
                this.form = props.formManager || context.formManager;
                this.eventHandlerQueue = [];

                if (!this.form) {
                    throw new Error(
                        `Could not find "formManager" in either the context or ` +
                            `props of "${this.constructor.displayName}". ` +
                            `Either wrap a parent component in a a @formManager, ` +
                            `or explicitly pass "formManager" as a prop to "${this.constructor.displayName}".`,
                    );
                }

                this.state = {
                    id: uniqueId('form-control'),
                    validator: props.validator,
                    value: props.defaultValue,
                    required: props.required,
                    messages: {},
                    pristine: true,
                    touched: false,
                    isValidating: false,
                };

                const result = this.state.validator(props.defaultValue, props.required, props);

                if (!isUndefined(result)) {
                    if (isFunction(result.then)) {
                        this.state.isValidating = true;
                        result.then((messages) => {
                            this.updateValidity(messages);
                        });
                    } else {
                        this.state.messages = result;
                    }
                }

                this.form.addControl(this);
            }

            isDefaultValueChanged(prev, next) {
                if (Immutable.isImmutable(prev) || Immutable.isImmutable(next)) {
                    return !Immutable.is(prev, next);
                }
                return prev !== next;
            }

            /**
             * If the validator function has updated, need to revalidate.
             */
            UNSAFE_componentWillReceiveProps(nextProps) {
                const defaultChanged =
                    this.isDefaultValueChanged(this.props.defaultValue, nextProps.defaultValue) &&
                    isUndefined(this.props.value);

                if (defaultChanged) {
                    this.updateValueOnly(nextProps.defaultValue);
                    this.validate(nextProps.defaultValue);
                }
                if (this.props.validator !== nextProps.validator) {
                    this.setState({
                        validator: nextProps.validator,
                    });
                    const v = defaultChanged ? nextProps.defaultValue : this.state.value;
                    this.eventHandlerQueue.push({
                        fn: () => {
                            return this.validate(v);
                        },
                        args: this,
                    });
                }
            }

            componentDidMount() {
                this.form.onInitialized();
            }

            componentWillUnmount() {
                this.form.removeControl(this);
            }

            /**
             * returns the name if provided or a unique Id which is in the form of `form-control<number>`
             * @returns {*}
             */
            get uniqueName() {
                // eslint-disable-next-line no-shadow -- SCLD-17998
                let uniqueId = this.name;

                const {formGroup} = this.context;
                if (!isUndefined(formGroup)) {
                    uniqueId =
                        this.name +
                        '$$' +
                        formGroup.collection +
                        '$$' +
                        (formGroup.parent ? formGroup.parent.uniqueName : '') +
                        '$$' +
                        this.state.id;
                }

                return uniqueId || this.state.id;
            }

            get name() {
                return this.props.name || this.state.id;
            }

            get group() {
                const {formGroup} = this.context;
                if (isUndefined(formGroup)) {
                    return null;
                }
                return formGroup;
            }

            /**
             * The value of the underlying form field. no constraints on type.
             * @returns {*}
             */
            get value() {
                return this.state.value;
            }

            /**
             * true if the field value passes validation (has no validation errors).
             * @returns {boolean}
             */
            get valid() {
                return isUndefined(this.state.messages.errors) || isEmpty(this.state.messages.errors);
            }

            get isValidating() {
                return this.state.isValidating;
            }

            get messages() {
                return this.state.messages;
            }

            /**
             * `true` if the field value is the same as its initialized value. Opposite of `dirty`.
             * @returns {boolean}
             */
            get pristine() {
                return this.state.pristine;
            }

            /**
             * `true` if the field value has changed from its initialized value. Opposite of `pristine`.
             * @returns {boolean}
             */
            get dirty() {
                return !this.state.pristine;
            }

            /**
             * `true` if the field has been touched
             * @returns {boolean}
             */
            get touched() {
                return this.state.touched;
            }

            /**
             * `true` if the field has been untouched. Opposite of `touched`
             * @returns {boolean}
             */
            get untouched() {
                return !this.state.touched;
            }

            getWrappedInstance() {
                // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                return this.refs.wrappedInstance;
            }

            reset() {
                this.setState({
                    pristine: true,
                    messages: [],
                });
                this.updateValueOnly(this.props.defaultValue);
                //this.validate(this.props.defaultValue,pristine);
            }

            markAsTouched(actionId) {
                this.setState({
                    touched: true,
                });
                this.eventHandlerQueue.push({
                    fn: this.form.onTouched,
                    args: [this, actionId],
                });
            }

            markAsDirty() {
                this.setState({
                    pristine: false,
                });
            }

            updateValueOnly(v) {
                this.setState({value: v});
                this.eventHandlerQueue.push({
                    fn: this.form.onChanged,
                    args: [this, v],
                });
            }

            updateValue(v) {
                if (!isPristine(this.state.value, v)) {
                    this.markAsDirty();
                    this.updateValueOnly(v);
                }
            }

            updateValidity(messages) {
                this.setState({
                    isValidating: false,
                    messages,
                });

                this.eventHandlerQueue.push({
                    fn: this.form.updateValidity,
                    args: this,
                });
            }

            updateValueAndValidate(v) {
                this.updateValue(v);
                this.validate(v);
            }

            validate(v) {
                const _this = this;
                const result = this.state.validator(v, this.props.required, this.props);

                if (isUndefined(result)) {
                    // No validation function declared.
                    return;
                }
                this.setState({isValidating: true});
                if (isFunction(result.then)) {
                    result.then((messages) => {
                        _this.updateValidity(messages);
                    });
                } else {
                    this.updateValidity(result);
                }
            }

            focus() {
                // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                if (!isUndefined(this.refs.wrappedInstance.focus)) {
                    // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                    this.refs.wrappedInstance.focus();
                }
            }

            /**
             * --------------
             * --------------
             * Event Handlers
             * --------------
             * --------------
             */

            _onChange(v) {
                if (this.form.touchOnChange) {
                    this.markAsTouched();
                }
                this.updateValueAndValidate(v);
                this.props.onChange(v);
            }

            _onTouched() {
                if (this.form.touchOnBlur) {
                    this.markAsTouched();
                }
            }

            /**
             * Items in the queue might themselves add to the queue. Track new
             * items in `this.eventHandlerQueue` while dequeueing old items.
             */
            componentDidUpdate() {
                const _this = this;
                const oldQueue = this.eventHandlerQueue;
                this.eventHandlerQueue = [];
                forEach(oldQueue, (o) => {
                    o.fn.apply(_this.form, o.args);
                });
            }

            sanitizeProps(props) {
                const p = omit(props, ['formManager', 'validator', 'defaultValue', 'name', 'onChange']);

                return Immutable.fromJS(omitBy(p, isFunction));
            }

            shouldComponentUpdate(nextProps, nextState, nextContext) {
                return (
                    !Immutable.is(this.sanitizeProps(this.props), this.sanitizeProps(nextProps)) ||
                    !Immutable.is(this.state, nextState)
                );
            }

            render() {
                if (!this.form.isRegistered(this)) {
                    return null;
                }

                const sanitizedProps = omit(this.props, [
                    'formManager',
                    'validator',
                    'defaultValue',
                    'name',
                    'onChange',
                ]);

                return (
                    <WrappedComponent
                        // eslint-disable-next-line react/no-string-refs -- SCLD-18005
                        ref='wrappedInstance'
                        onChange={this._onChange.bind(this)}
                        onTouched={this._onTouched.bind(this)}
                        valid={this.valid}
                        triedSubmit={this.form.triedSubmit}
                        {...this.state}
                        {...sanitizedProps}
                    />
                );
            }
        }

        return Control;
    };
}

import axios from 'axios';
import * as Immutable from 'immutable';
import QS from 'qs';

import get from 'lodash/get';
import isDate from 'lodash/isDate';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';

import * as EnvironmentConstants from 'constants/environment-constants';
import ErrorActionTypes from 'constants/error-action-types';
import ErrorCodes from 'constants/error-codes';
// constants
import {UNAUTHORIZED_REQUEST} from 'constants/session-constants';

import {fullUrl} from 'utils/api-utils';
// utils
import JSONUtils from 'utils/json-utils';
import MeteorCookies from 'utils/meteor-cookies';
import UIErrorCodeUtils from 'utils/ui-error-code-utils';

export const CALL_API = Symbol('CALL API'); // for use with redux-style API actions

const simcaptureUiVersion = __VERSION__;
const uiService = __UI_SERVICE__;

/**
 * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
 * @typedef {import('axios').Method} Method
 * @typedef {import('immutable').Map} ImmutableMap
 * @typedef {import('immutable').List} ImmutableList
 * @typedef {import('utils/api-utils').RequestOptions} RequestOptions
 * @typedef { RequestOptions & {
 *      types: string[],
 *      endpoint: string;
 *      method: Method;
 *      success: (data: any) => void;
 *      failure: (data: ImmutableMap | ImmutableList) => void;
 *      forceFailureHandler: boolean;
 *      query: string;
 *      }
 * } ApiConfiguration
 *
 * createAuthenticatedRequestConfig sometimes reads query to infer `method`
 */

/**
 * @callback AxiosSuccessCallback
 * @param {import('axios').AxiosResponse} Response
 * @returns {import('axios').AxiosResponse}
 */

/**
 * @callback AxiosErrorCallback
 * @param {import('axios').AxiosError} Error
 * @returns {void}
 * @throws {import('axios').AxiosError} throws the original error
 */

// Configure axios to parse json dates properly
const PROTECTION_PREFIX = /^\)\]\}',?\n/;

axios.defaults.transformResponse = [
    function (data, headers) {
        if (typeof data === 'string') {
            data = data.replace(PROTECTION_PREFIX, '');
            data = JSONUtils.parseWithDate(data);
        }
        return data;
    },
];

axios.defaults.paramsSerializer = (params) => {
    return QS.stringify(params, {
        arrayFormat: 'repeat',
        filter: (prefix, value) => {
            if (value && (isDate(value) || value._isAMomentObject)) {
                return value.toJSON();
            }
            return value;
        },
    });
};

/**
 * API middleware
 *
 * http://redux.js.org/docs/advanced/Middleware.html
 *
 * This Redux middleware handles any actions that access the network. It intercepts each action and
 * checks to see if the action object has the key [CALL_API] defined on it. If so, the middleware
 * will handle the action. Otherwise it will pass the action unchanged to the next middleware.
 *
 * The value of the [CALL_API] property can be either an array of configuration objects or a single
 * configuration object. A configuration object looks like this :
 * {
        types: [
            constants.AUTHENTICATE_USER_REQUEST,
            constants.AUTHENTICATE_USER_SUCCESS,
            constants.AUTHENTICATE_USER_FAILURE
        ],
        endpoint: `/auth`,
        data,                                       // data field if this a PUT/POST
        method: 'POST',                             // HTTP Method, defaults to GET
        success,                                    // success handler
        failure,                                    // failure handler
        forceFailureHandler: true                   // forces the failure handler to be called for any errors
    }
 * It can also accept optional properties used to configure the network request. See
 * `createRequestConfig` for details. Note that the object has three action types instead of the
 * usual single action type. The middleware executes the network request, or, if [CALL_API] is an
 * array, it executes all the network requests specified in the configuration objects in the array.
 *
 * @param store
 */
const apiMiddleware = (store) => (next) => (action) => {
    // if the call was made to an operation that is not an API action (such as download file), return
    if (!action) {
        return;
    } else if (isNil(action[CALL_API])) {
        return next(action);
    } else if (__UNIT_TEST__) {
        // UNIT_TEST flag is set in webpack config
        return next({
            type: 'API_CALLS_DISABLED',
        });
    } else if (!isNil(action[CALL_API])) {
        return executeApiAction(store, next, action, action[CALL_API]);
    }
};

/**
 * Takes an API action, validates that it has the expected three-type structure,
 * and if it is valid, forwards the request action type as a non-API action to
 * the next Redux middleware. Then it executes the actual API network request.
 *
 * If the request succeeds, the success action type for this API action will be
 * triggered in onSuccess. Otherwise, the failure action type will be triggered
 * in onFailure. Hence, from this single API action, two regular Redux actions
 * should be passed to the next middleware.
 *
 * @param store
 * @param next
 * @param apiAction
 * @param {ApiConfiguration} apiConfig
 */
const executeApiAction = (store, next, apiAction, apiConfig) => {
    validateApiActionConfig(apiConfig);
    next(createRequestAction(apiAction, apiConfig));
    const requestConfig = createRequestConfig(store, apiConfig);

    return axios(requestConfig)
        .then(onSuccess(next, apiAction, apiConfig))
        .catch(onFailure(next, apiAction, apiConfig));
};

/**
 * Generates an axios request config from the configuration specified in the
 * API action. There are three types of requests:
 * - Unauthenticated requests
 * - Authentication requests
 * - Authenticated requests
 *
 * @param store
 * @param {ApiConfiguration} apiConfig
 */
const createRequestConfig = (store, apiConfig) => {
    const {client, user, linkId} = store.getState().session;
    const tokenFromCookies = MeteorCookies.getInstance().get('token');
    const commonHeaders = {
        'action-type': apiConfig.types[0],
        'service-consumer-name': EnvironmentConstants.SERVICE_CONSUMERS.SIMCAPTURE_CLOUD,
        'service-consumer-version': simcaptureUiVersion,
        'service-consumer-service': uiService,
    };

    if (apiConfig.headers?.['use-transitional-auth']) {
        commonHeaders['use-transitional-auth'] = apiConfig.headers['use-transitional-auth'];
    }

    if (!user.isEmpty()) {
        commonHeaders['user-id'] = user.get('userId');
    }

    if (isAuthentication(apiConfig.endpoint)) {
        return createAuthRequestConfig(apiConfig, commonHeaders);
    }

    return createAuthenticatedRequestConfig(apiConfig, commonHeaders, client, tokenFromCookies, linkId);
};

/**
 * Configures axios call for a basic authentication request.
 * @param {ApiConfiguration} apiConfig
 * @param commonHeaders
 * @returns {AxiosRequestConfig}
 */
function createAuthRequestConfig(apiConfig, commonHeaders) {
    const data = apiConfig.data.toJS();
    const optionalHeaders = {};
    if (!isNil(data.client)) {
        optionalHeaders['client-name'] = data.client;
    }
    return {
        url: fullUrl(apiConfig.endpoint),
        method: 'post',
        data,
        headers: {
            ...optionalHeaders,
            ...commonHeaders,
        },
    };
}

/**
 * Configures axios call for an authenticated request.
 *
 * @param {ApiConfiguration} apiConfig
 * @param commonHeaders
 * @param client
 * @param token
 * @param linkId
 */
function createAuthenticatedRequestConfig(apiConfig, commonHeaders, client, token, linkId) {
    const optionalHeaders = {};
    if (!isNil(client.clientId)) {
        optionalHeaders['client-id'] = client.clientId;
    }
    if (!isNil(linkId)) {
        optionalHeaders['link-id'] = linkId;
    }

    /** @type {AxiosRequestConfig} */
    const requestConfig = {
        url: fullUrl(apiConfig.endpoint),
        headers: {
            token: token,
            ...optionalHeaders,
            ...commonHeaders,
        },
    };

    // if method is defined, normalize method capitalization; otherwise infer from whether we have data/query
    if (apiConfig.method) {
        requestConfig.method = apiConfig.method.toLowerCase();
    } else if (apiConfig.query) {
        requestConfig.method = 'post';
    } else if (apiConfig.data) {
        requestConfig.method = 'put';
    } else {
        requestConfig.method = 'get';
    }

    // data should be plain javascript
    if (Immutable.isImmutable(apiConfig.data)) {
        requestConfig.data = apiConfig.data.toJS();
    } else {
        requestConfig.data = apiConfig.data;
    }

    if (apiConfig.contentType) {
        requestConfig.headers['Content-Type'] = apiConfig.contentType;
    }

    if (apiConfig.responseType) {
        requestConfig.responseType = apiConfig.responseType;
    }

    if (apiConfig.params) {
        requestConfig.params = apiConfig.params;
    }

    if (apiConfig.onUploadProgress) {
        requestConfig.onUploadProgress = apiConfig.onUploadProgress;
    }

    if (apiConfig.onDownloadProgress) {
        requestConfig.onDownloadProgress = apiConfig.onDownloadProgress;
    }

    if (apiConfig.transformResponse) {
        requestConfig.transformResponse = apiConfig.transformResponse;
    }

    return requestConfig;
}

/**
 * Callback to handle a successful network request for an API action. If the
 * action defines a success callback, it is called here. The success action type
 * for the API action is passed to the next middleware. The response is returned
 * so the Promise returned by axios can also be used by the code that dispatched
 * the action.
 *
 * @param next
 * @param apiAction
 * @param {ApiConfiguration} apiConfig
 * @returns {AxiosSuccessCallback}
 */
const onSuccess = (next, apiAction, apiConfig) => {
    return (response) => {
        next(createSuccessAction(apiAction, apiConfig, response));

        if (isFunction(apiConfig.success)) {
            apiConfig.success(Immutable.fromJS(response.data));
        }

        return response;
    };
};

/**
 * indicates if the apiConfig defines a failure action
 * @param {ApiConfiguration} apiConfig
 */
const hasFailureAction = (apiConfig) => {
    return apiConfig.types.length === 3;
};

/**
 * Helper that indicates if a given response is one of the errors we would dispatch an ACTION_ERROR
 * for before. We want to remove these eventually, but for now, we're maintaining the same functionality
 * by checking for them before other errors.
 *
 * TODO: MET-4480 make these capable of being handled by the regular 400/500 level handlers
 *
 * @param {import('axios').AxiosResponse} response
 * @returns {boolean}
 */
const isBackwardCompatibleError = (response) => {
    // eslint-disable-next-line no-shadow -- SCLD-17998
    let isBackwardCompatibleError = false;
    const method = response.config.method.toLowerCase();
    const errorCode = get(response, 'data');
    if (
        method === 'put' ||
        (method === 'post' && response.status !== 404 && response.status !== 422) ||
        method === 'delete'
    ) {
        if (
            !isNil(errorCode) &&
            (UIErrorCodeUtils.errorCodesAreEqual(ErrorCodes.BAD_REQUEST_VALIDATION, errorCode) ||
                UIErrorCodeUtils.errorCodesAreEqual(ErrorCodes.BAD_REQUEST, errorCode) ||
                UIErrorCodeUtils.errorCodesAreEqual(ErrorCodes.BAD_DATA, errorCode) ||
                UIErrorCodeUtils.errorCodesAreEqual(ErrorCodes.CONFLICT, errorCode) ||
                UIErrorCodeUtils.errorCodesAreEqual(ErrorCodes.NOT_FOUND, errorCode))
        ) {
            isBackwardCompatibleError = true;
        }
    }
    return isBackwardCompatibleError;
};

/**
 * Callback to handle a failed network request for an API action. If the
 * action error response is an authentication error, an action of type
 * UNAUTHORIZED_REQUEST is created for the UI to detect it globally and display
 * an appropriate message.
 * If the action is a 400 level error we do the following:
 * - if it defines a failure callback, we call it
 * - if it has a FAILURE const in its apiConfig, we pass a failure API action to the next middleware (for use with stores)
 * - if it has neither a failure callback nor a FAILURE const, we treat it the same was as a 500 error
 * If the action is a 500 level error, we dispatch an ErrorActionType.ACTION_ERROR for the UI to detect
 * globally and display our unknown error message.
 * The response is returned so the Promise returned by axios can also be used by
 * the code that dispatched the action.
 *
 * @param next
 * @param apiAction
 * @param {ApiConfiguration} apiConfig
 * @returns {AxiosErrorCallback}
 */
export const onFailure = (next, apiAction, apiConfig) => {
    return (error) => {
        const {response} = error;
        if (!response) {
            // This is an axios-level error with no response data
            throw error;
        }

        if (apiConfig.forceFailureHandler) {
            // forceFailureHandler flag forces it to call the failure callback, as well as throws a 500 Action error
            if (hasFailureAction(apiConfig)) {
                // if there's a failure const, then dispatch an error action for the store to handle
                next(createFailureAction(apiAction, apiConfig.types[2], response));
            }
            apiConfig.failure(Immutable.fromJS(response.data));
            next(createFailureAction(apiAction, ErrorActionTypes.ACTION_ERROR, response));
        } else if (response.status === 401 || response.status === 403) {
            // handle unauthorized requests
            next({
                type: UNAUTHORIZED_REQUEST,
                data: {apiAction, response},
            });
        } else if (isBackwardCompatibleError(response)) {
            // for backwards compatibility, catch a specific set of errors and dispatch the action error, as well as,
            // triggering the action and failure callback if they exist
            next(createFailureAction(apiAction, ErrorActionTypes.ACTION_ERROR, response));
            if (isFunction(apiConfig.failure)) {
                // if there's a failure function, call it
                apiConfig.failure(Immutable.fromJS(response.data));
            }
            if (hasFailureAction(apiConfig)) {
                // if there's a failure const, then dispatch an error action for the store to handle
                next(createFailureAction(apiAction, apiConfig.types[2], response));
            }
        } else if (response.status >= 400 && response.status < 500) {
            // 400 level errors are known errors sent by the server
            if (isFunction(apiConfig.failure)) {
                // if there's a failure function, call it
                apiConfig.failure(Immutable.fromJS(response.data));
            }
            if (hasFailureAction(apiConfig)) {
                // if there's a failure const, then dispatch an error action for the store to handle
                next(createFailureAction(apiAction, apiConfig.types[2], response));
            }

            if (!isFunction(apiConfig.failure) && !hasFailureAction(apiConfig)) {
                // if there's no failure function and no failure const, then treat this as an unexpected error
                next(createFailureAction(apiAction, ErrorActionTypes.ACTION_ERROR, response));
            }
        } else {
            // 500 level error, dispatch the global error
            next(createFailureAction(apiAction, ErrorActionTypes.ACTION_ERROR, response));
        }

        throw error;
    };
};

function isAuthentication(endpoint) {
    return endpoint.indexOf('auth') !== -1 && !isSSOAuthentication(endpoint);
}

function isSSOAuthentication(endpoint) {
    return endpoint.indexOf('sso') !== -1;
}

/**
 * Validates the API action configuration.
 *
 * @param {ApiConfiguration} apiConfig
 */
function validateApiActionConfig(apiConfig) {
    const {endpoint, types} = apiConfig;
    if (!isString(endpoint)) {
        throw new Error(`API actions must have an 'endpoint' attribute, which must be a string.`);
    }

    if (!Array.isArray(types) || types.length < 2 || types.length > 3) {
        throw new Error(
            `API actions must have a 'type' attribute, which must be an array of two or three action types.`,
        );
    }

    if (
        !types.every((type) => {
            return typeof type === 'string';
        })
    ) {
        throw new Error('Action types must be strings.');
    }

    if (!types[0].includes('REQUEST')) {
        // eslint-disable-next-line
        console.warn('API action type might not be correct. First is for request:', types[0]);
    }

    if (!types[1].includes('SUCCESS')) {
        // eslint-disable-next-line
        console.warn('API action type might not be correct. Second is for success:', types[1]);
    }

    if (types.length > 2 && !types[2].includes('FAILURE')) {
        // eslint-disable-next-line
        console.warn('API action type might not be correct. Third is for failure:', types[2]);
    }
}

/**
 * @param apiAction
 * @param {ApiConfiguration} apiConfig
 */
function createRequestAction(apiAction, apiConfig) {
    const action = Object.assign({}, apiAction, {
        type: apiConfig.types[0],
        data: apiConfig.data,
    });
    delete action[CALL_API];
    return action;
}

/**
 * @param apiAction
 * @param {ApiConfiguration} apiConfig
 * @param {import('axios').AxiosResponse} response
 */
function createSuccessAction(apiAction, apiConfig, response) {
    let data = response.data;
    if (!isAuthentication(apiConfig.endpoint)) {
        data = Immutable.fromJS(data);
    }
    const action = Object.assign({}, apiAction, {
        type: apiConfig.types[1],
        data,
    });
    delete action[CALL_API];
    return action;
}

function createFailureAction(apiAction, configType, response) {
    const action = Object.assign({}, apiAction, {
        type: configType,
        data: Immutable.fromJS(response.data),
    });
    delete action[CALL_API];
    return action;
}

export default apiMiddleware;

import {useEffect, useState} from 'react';

import IntlUtils from 'utils/intl-utils';

import type {LocaleModule} from 'constants/intl-constants';

/**
 * Given one or more UI module names, calls `ensureLocaleMessages` and returns true to the React component using the
 * hook once the messages have been loaded into the intl store for all of the specified module names.
 *
 * @param moduleNames
 */
const useIntlMessages = (...moduleNames: LocaleModule[]): boolean => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const ensureLocaleMessages = async () => {
            await IntlUtils.ensureLocaleMessages(...moduleNames);
            setIsLoaded(true);
        };

        ensureLocaleMessages();
    }, [...moduleNames]);

    return isLoaded;
};

export default useIntlMessages;

import noop from 'lodash/noop';
import {createContext} from 'react';
import {AccessibilityOptions} from 'views/signed-in/accessibility/accessibility-constants';

type ContextType = {
    accessibilitySettings: AccessibilityOptions;
    setAccessibilitySettings: (values: AccessibilityOptions) => void;
};

export const AccessibilityContext = createContext<ContextType>({
    accessibilitySettings: {
        theme: 'LIGHT',
        reduceMotion: false,
    },
    setAccessibilitySettings: noop,
});
